import React from "react";
import { Button } from "antd";
import { QuestionNode } from "./QuestionNode.jsx";
import { uuidv4 } from "../../utils/utils.js";
import { set } from "lodash";


// Input: question object, schema
//
export function Question(props) {
	const datasource = props.userInfo?.datasources[ props.currentDatasourceId ];
	const schema = datasource?.schema;
	let question = datasource?.questions[ props.questionId ];
	const [ show_me_button_text, set_show_me_button_text ] = React.useState("Show me");

	return (
		<div style={{ display: "flex", width: "100%" }}>
			<Button
				style={{
					width: "120px",
				}}
				onClick={() => {
					set_show_me_button_text("Loading...");
					props.updateQuestion(question, set_show_me_button_text);
				}}

				size={'large'}
				type="link"
			>
				{show_me_button_text}
			</Button>

			<div style={{ display: "flex", width: "100%" }}>
				{question?.nodes?.length > 0
					? question.nodes.map((node, index) => {
						return (
							<QuestionNode
								key={uuidv4()}
								question={question}
								nodeIndex={index}
								schema={schema}
								updateQuestion={props.updateQuestion}
								userInfo={props.userInfo}
								datasource={datasource}
							></QuestionNode>
						);
					})
					: ""}
			</div>
		</div>
	);
}
