import React, { useState } from 'react';
import { Select, Space } from "antd";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart, PieChart, Line, Pie } from 'recharts';

export function Charting({ columnDefs, rowData }) {
    const [ colX, setColX ] = useState(0);
    const [ colY, setColY ] = useState(0);

    if(columnDefs === null || columnDefs === undefined) {
        return(
            <>
                <div>Select a question or write a new one above.</div>
            </>
        )
    }

    // will later replace this with smart selector
    // for now this will handle removal of columns
    if(colX >= columnDefs.length) {
        setColX(columnDefs.length - 1)
        return;
    }
    if(colY >= columnDefs.length) {
        setColY(columnDefs.length - 1)
        return;
    }
    
    return (
        <>
            <Space>
                <div>X Axis</div>
                <PickAxis columnDefs={columnDefs} setCol={setColX} col={colX} />
                <div>Values</div>
                <PickAxis columnDefs={columnDefs} setCol={setColY} col={colY}/>
                <PlotBarChart xKey={columnDefs[ colX ].field} xValues={columnDefs[ colY ].field} data={rowData} />
                {/* <PlotLineChart xKey={columnDefs[colX].field} xValues={columnDefs[colY].field} data={rowData}/> */}
                {/* <PlotPieChart xKey={columnDefs[colX].field} xValues={columnDefs[colY].field} data={rowData}/> */}
            </Space>
        </>
    )
}

function PlotPieChart(props) {
    return (
        <PieChart width={730} height={250}>
            <Pie data={props.data} dataKey={props.xValues} nameKey={props.xKey} cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
        </PieChart>
    )
}

function PlotLineChart(props) {
    return (
        <LineChart width={700} height={400} data={props.data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={props.xKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={props.xValues} stroke="#8884d8" />
        </LineChart>
    )
}

function PlotBarChart(props) {
    return (
        <BarChart width={700} height={400} data={props.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={props.xKey} />
            <YAxis />
            <Legend />
            <Tooltip />
            <Bar dataKey={props.xValues} fill="#8884d8" />
        </BarChart>
    )
}


function PickAxis({ columnDefs, setCol, col }) {
    function handleChange(value, data) {
        setCol(data.key)
    }

    return (
        <Select
            value={columnDefs[ col ].field}
            style={{
                width: "100%",
            }}
            dropdownStyle={{ minWidth: "50%" }}
            onChange={handleChange}
            options={
                columnDefs
                    ? columnDefs.map((col, index) => {
                        return { label: col.field, value: col.field, key: index };
                    })
                    : []
            }
        ></Select>
    )
}


export default Charting;