import "./App.css";
import React, { useEffect, useState } from "react";

import { Button, Select, Popover, Menu, Empty, Image } from "antd";
import { Typography, Space } from "antd";

const { Text } = Typography;
import { DatasourcePopover, LoginPage, LandingPage, Dashboard } from "./components";
import UserContext from "./Contexts.js";
import { Layout, Form, Input, Collapse, Modal, Table } from "antd";
const { SubMenu } = Menu;
const { Panel } = Collapse;

const { Header, Footer, Sider, Content } = Layout;
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";

const { Option } = Select;

export default function App() {
  let user_id = localStorage.getItem("user_id");
  let user_token = localStorage.getItem("user_token");
  const [ userObject, setUserObject ] = useState({
    user_id: user_id,
    user_token: user_token,
  });

  function handleLogout() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_token");
    setUserObject({ user_id: "", user_token: "" });
  }
  return (
    <Router>
      <UserContext.Provider value={{ userObject: userObject, setUserObject: setUserObject }}>
        <div className="App">
          <Layout>
            <Header
              style={{
                backgroundColor: "#f0f2f5",
                position: "fixed",
                zIndex: 1,
                width: "100%",
                display: "flex",
                // "justify-content": "end"
              }}
            >
              <Link to="/">
                <div className="logo">
                  <Image preview={false} width={200} src={require("./human-software logo clean.png")} />
                </div>
              </Link>
              <div style={{ width: "100%" }}></div>
              {userObject.user_id ? (
                <>
                  <Link to="/dashboard">
                    <Button type="primary">Dashboard</Button>
                  </Link>
                  <Link to="/">
                    <Button onClick={handleLogout}>Logout</Button>
                  </Link>
                </>
              ) : (
                <Link to="/login">
                  <Button type="primary">Try free</Button>
                </Link>
              )}
            </Header>
            <Content
              style={{
                margin: "24px 16px 0",
                overflow: "initial",
                display: "flex",
                flexWrap: "wrap",
                marginTop: 64,
              }}
            >
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="/dashboard" element={<Dashboard></Dashboard>} />
              </Routes>
            </Content>

            <div className="site-layout-background" style={{ padding: 24, textAlign: "center" }}></div>

            <Footer style={{ textAlign: "center" }}>human.software</Footer>
          </Layout>
        </div>
      </UserContext.Provider>
    </Router>
  );
}
