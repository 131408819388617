import React, { useState, useRef } from "react";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { Button, Popover, Row, Col, Input } from "antd";
import { Typography } from "antd";
import { MySQLTypeMapper, PGTypeMapper, SnowflakeTypeMapper, ESTypeMapper } from "../../utils/utils";
const { Text } = Typography;

export function FilterPopover(props) {
	const [ filterRules, setFilterRules ] = useState(props.question.nodes[ props.nodeIndex ].booleanQuery);
	const [ filterState, setFilterState ] = useState(isRuleComplete(filterRules) ? "some" : "all");
	console.log(props.question.nodes[ props.nodeIndex ].booleanQuery);
	const handlePopoverOpenChange = (newOpen) => {
		if (newOpen === true) {
			props.setCascaderDisabled(newOpen);
			setOpen(newOpen);
		}
	};
	const enableFilter = (event) => {
		setFilterState("some");
	};
	const handleFiltersClick = (event) => {
		setFilterState("all");
	};
	let QueryBuilderFieldMode = 'DropdownTree';
	let QueryBuilderSeparator = '\'s ';

	function getColumnData(question, nodeIndex, schema) {
		const tableFilters = {};  // Store filters for each table

		function tableIsShownInPreviousNode(path, table) {
			return path.some((p, idx) =>
				(p.source.table === table || p.target?.table === table) && idx < path.length - 1
			);
		}

		function getTypeMapped(fieldType) {
			switch (props.datasource.db_type) {
				case "PostgreSQL":
					return PGTypeMapper(fieldType);
				case "MySQL":
					return MySQLTypeMapper(fieldType);
				case "Snowflake":
					return SnowflakeTypeMapper(fieldType);
				case "FEC":
					return ESTypeMapper(fieldType);
				default:
					return fieldType;
			}
		}

		function getTableColumns(namespace, table) {
			const fields = (schema.namespaces[ namespace ]?.tables[ table ]?.direct_fields || []).map(field => {
				const name = Object.keys(field)[ 0 ];
				const type = getTypeMapped(Object.values(field)[ 0 ]);
				return { field: name, label: name, type: type };
			});

			// Load filters for the table if it has appeared in a previous node
			if (tableFilters[ table ]) {
				fields.filters = tableFilters[ table ];
			}

			return fields;
		}

		const node = question.nodes[ nodeIndex ];
		const path = node.path;
		const columns = [];

		for (const segment of path) {
			const sourceNamespace = segment.source.namespace;
			const sourceTable = segment.source.table;

			if (!tableFilters[ sourceTable ] && node.filters) {
				// Set filters for the table
				tableFilters[ sourceTable ] = node.filters[ sourceTable ];
			}

			columns.push({
				field: sourceTable,
				label: sourceTable,
				columns: getTableColumns(sourceNamespace, sourceTable)
			});

			if (segment.target) {
				const targetNamespace = segment.target.namespace;
				const targetTable = segment.target.table;

				if (!tableFilters[ targetTable ] && node.filters) {
					tableFilters[ targetTable ] = node.filters[ targetTable ];
				}

				columns.push({
					field: targetTable,
					label: targetTable,
					columns: getTableColumns(targetNamespace, targetTable)
				});
			}
		}

		return columns;
	}


	function isRuleComplete(filters) {
		// If filters is not defined or lacks essential properties, it's incomplete.
		if (!filters ||
			!filters.label || filters.label === "" ||
			!filters.field || filters.field === "" ||
			!filters.type || filters.type === "" ||
			!filters.operator || filters.operator === "") {
			return false;
		}

		// For certain operators, no value is required. But for others, a value is mandatory.
		let noValueRequiredOperators = [ "isnull", "isnotnull", "isempty", "isnotempty" ];
		if (!noValueRequiredOperators.includes(filters.operator) && (!filters.value || filters.value === "")) {
			return false;
		}

		// Check nested rules
		if (filters.rules) {
			for (let rule of filters.rules) {
				if (!isRuleComplete(rule)) {  // If any nested rule is incomplete, return false.
					return false;
				}
			}
		}

		// If we made it here, the rule (and any nested rules) are complete.
		return true;
	}

	function handleQBChange(event) {
		// Save the instance so we can use it to convert the rules to SQL
		console.log(this.getRules());
		// console.log(this.getSqlFromRules());
		setFilterRules(this.getRules());
		if (isRuleComplete(this.getRules())) {
			setFilterState("some");
		} else {
			setFilterState("all");
		}
	}


	const content = (
		<div onMouseDown={e => e.stopPropagation()}>
			<QueryBuilderComponent
				rule={props.question.nodes[ props.nodeIndex ].booleanQuery}
				width='100%'
				fieldMode={QueryBuilderFieldMode}
				separator={QueryBuilderSeparator}
				change={handleQBChange}
				columns={getColumnData(props.question, props.nodeIndex, props.schema)}></QueryBuilderComponent>
		</div>
	);
	const [ open, setOpen ] = useState(false);

	const hidePopover = () => {
		if (open === true) {
			setOpen(false);
			props.setCascaderDisabled(false);
			let question = props.question;
			let nodeIndex = props.nodeIndex;
			// Update the question both locally and remotely with the new filters (if different than before)
			if (question.nodes[ nodeIndex ].booleanQuery !== filterRules) {
				question.nodes[ nodeIndex ].booleanQuery = filterRules;
				props.updateQuestion(question);
			}
		}
	};


	return (
		<>
			<Popover onOpenChange={handlePopoverOpenChange}
				content={content}
				placement="top"
				title={<Row><Col span={11}>Filters</Col><Col span={12}><Button onClick={hidePopover} >Close</Button></Col></Row >}
				trigger="click"
				open={open}
			>
				<Button type="link" onClick={hidePopover} >{filterState}</Button>
			</Popover >
			<Text key={props.label}>{props.label}</Text>
		</>
	);
}
