import { operationValues, requiresPreviousOperation } from "./common_definitions";
import _ from 'lodash';


// NOT cryptographically safe, just used as keys for React. Do not use for security stuff.
export function uuidv4() {
  return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[ 0 ] & (15 >> (c / 4)))).toString(16)
  );
}

export function deepCopy(obj, hash = new WeakMap()) {
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  // If the object is already in the hash map, return the new reference.
  if (hash.has(obj)) return hash.get(obj);

  let clonedObj;
  if (obj instanceof Array) {
    clonedObj = [];
    hash.set(obj, clonedObj);
    for (let i = 0; i < obj.length; i++) {
      clonedObj[ i ] = deepCopy(obj[ i ], hash);
    }
  } else if (obj instanceof Date) {
    clonedObj = new Date(obj);
  } else if (obj instanceof RegExp) {
    clonedObj = new RegExp(obj);
  } else if (obj instanceof Map) {
    clonedObj = new Map();
    hash.set(obj, clonedObj);
    for (let [ key, value ] of obj) {
      clonedObj.set(deepCopy(key, hash), deepCopy(value, hash));
    }
  } else if (obj instanceof Set) {
    clonedObj = new Set();
    hash.set(obj, clonedObj);
    for (let value of obj) {
      clonedObj.add(deepCopy(value, hash));
    }
  } else {
    clonedObj = Object.create(Object.getPrototypeOf(obj));
    hash.set(obj, clonedObj);
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[ key ] = deepCopy(obj[ key ], hash);
      }
    }
  }

  return clonedObj;
}



export function MySQLTypeMapper(MySQLType) {
  if (
    [
      "tinyint",
      "bit",
      "smallint",
      "mediumint",
      "int",
      "integer",
      "bigint",
      "decimal",
      "dec",
      "float",
      "double"

    ].some(type => MySQLType.split(/[\s.,;:!?(){}[\]"']+/).some(typeword => typeword === type))
  ) {
    return "number";
  } else if ([ , "bool", "boolean" ].includes(MySQLType)) {
    return "boolean";
  } else if ([ "char", "varchar", "binary", "varbinary", "blob", "text", "enum", "set" ].some(type => MySQLType.split(/[\s.,;:!?(){}[\]"']+/).some(typeword => typeword === type))) {
    return "string";
  } else if (
    [
      "timestamp",
      "date",
      "time",
      "datetime",
      "year"
    ].some(type => MySQLType.split(/[\s.,;:!?(){}[\]"']+/).some(typeword => typeword === type))
  ) {
    return "date";
  } else if ([ "bytea" ].includes(MySQLType)) {
    // TODO: this needs to be treated differently, maybe hidden?
    return "number";
  } else return "no mapping";
}


export function PGTypeMapper(PGType) {
  if (
    [
      "smallint",
      "integer",
      "bigint",
      "decimal",
      "numeric",
      "real",
      "double precision",
      "smallserial",
      "serial",
      "bigserial",
      "money",
      "numeric"
    ].some(type => PGType.split(/[\s.,;:!?(){}[\]"']+/).some(typeword => typeword === type))
  ) {
    return "number";
  } else if ([ "boolean" ].includes(PGType)) {
    return "boolean";
  } else if ([ "character varying", "varchar", "character", "char", "text", "uuid" ].includes(PGType)) {
    return "string";
  } else if (
    [
      "timestamp with time zone",
      "timestamp without time zone",
      "time with time zone",
      "time without time zone",
      "date",
      "time",
    ].includes(PGType)
  ) {
    return "date";
  } else if ([ "bytea" ].includes(PGType)) {
    // TODO: this needs to be treated differently, maybe hidden?
    return "number";
  } else return "no mapping";
}

export function SnowflakeTypeMapper(SFType) {
  SFType = SFType.toUpperCase();  // Convert to uppercase for consistency

  if (
    [
      "NUMBER", "INTEGER", "BIGINT", "SMALLINT", "FLOAT", "DOUBLE", "DECIMAL", "REAL"
    ].includes(SFType)
  ) {
    return "number";
  } else if ([ "BOOLEAN" ].includes(SFType)) {
    return "boolean";
  } else if ([
    "STRING", "TEXT", "VARCHAR", "CHAR", "FIXED"
  ].includes(SFType)) {
    return "string";
  } else if (
    [
      "TIMESTAMP_LTZ", "TIMESTAMP_NTZ", "TIMESTAMP_TZ", "DATE", "TIME", "TIME_LTZ", "TIME_NTZ", "TIME_TZ"
    ].includes(SFType)
  ) {
    return "date";
  } else if ([ "BINARY", "VARBINARY" ].includes(SFType)) {
    return "number";  // Again, this might need to be treated differently based on your use case
  } else if ([
    "OBJECT", "ARRAY", "VARIANT", "ANY"
  ].includes(SFType)) {
    return "json";  // Assuming you want to map semi-structured data to a 'json' type
  } else if ([ "GEOGRAPHY" ].includes(SFType)) {
    return "geospatial";  // Assuming you have a custom type for geospatial data
  } else return "no mapping";
}

export function ESTypeMapper(ESType) {
  ESType = ESType.toLowerCase();  // Normalize to lowercase for consistency

  // Numeric types
  if ([
    "byte", "short", "integer", "long", "half_float", "float", "double", "scaled_float", "unsigned_long"
  ].includes(ESType)) {
    return "number";
  }

  // Boolean type
  else if ([ "boolean" ].includes(ESType)) {
    return "boolean";
  }

  // String types
  else if ([
    "text", "keyword", "wildcard", "binary"
  ].includes(ESType)) {
    return "string";
  }

  // Date types
  else if ([
    "date", "date_nanos"
  ].includes(ESType)) {
    return "date";
  }

  // Range types
  else if ([
    "integer_range", "float_range", "long_range", "double_range", "date_range"
  ].includes(ESType)) {
    return "range";
  }

  // IP type
  else if ([ "ip" ].includes(ESType)) {
    return "ip";
  }

  // Geo types
  else if ([ "geo_point", "geo_shape" ].includes(ESType)) {
    return "geospatial";
  }

  // Completion type
  else if ([ "completion" ].includes(ESType)) {
    return "completion";
  }

  // Token count type
  else if ([ "token_count" ].includes(ESType)) {
    return "token_count";
  }

  // Specialized types
  else if ([ "murmur3", "annotated_text" ].includes(ESType)) {
    return "specialized";
  }

  // Percolator type (for queries)
  else if ([ "percolator" ].includes(ESType)) {
    return "query";
  }

  // Rank types
  else if ([ "rank_feature", "rank_features" ].includes(ESType)) {
    return "ranking";
  }

  // Flattened object
  else if ([ "flattened" ].includes(ESType)) {
    return "flattened";
  }

  // Vector types (for ML)
  else if ([ "dense_vector", "sparse_vector" ].includes(ESType)) {
    return "vector";
  }

  // Object/Nested types
  else if ([ "object", "nested" ].includes(ESType)) {
    return "json";  // Mapping object and nested to JSON-like structure
  }

  // Alias type
  else if ([ "alias" ].includes(ESType)) {
    return "alias";
  }

  // Join type
  else if ([ "join" ].includes(ESType)) {
    return "join";
  }

  // If no matching type
  else return "no mapping";
}

export function* getNextCounter() {
  let start = 1;
  while (true) {
    yield start;
    start++;
  }
}

export function getValidCollectionsAndFields(operation, nodeIndex, question, schema, allowed_namespaces) {
  let validFieldsByCollection = {};
  let allNamespaces = deepCopy(Object.keys(schema.namespaces));
  let validCollections = [];
  let allCollections = [];

  // Remove namespaces that either have zero tables or have no tables with direct fields
  // and namespaces that are not in the allowed_namespaces list
  allNamespaces = allNamespaces.filter((namespace) => {
    let tablesInNamespace = Object.keys(schema.namespaces[ namespace ].tables);
    let hasDirectFields = tablesInNamespace.some((table) => {
      return schema.namespaces[ namespace ].tables[ table ].direct_fields;
    });
    // also remove namespaces that have tables with no primary key(s)
    let hasPrimaryKey = tablesInNamespace.some((table) => {
      return schema.namespaces[ namespace ].tables[ table ].primary_key;
    });
    return hasDirectFields && allowed_namespaces.includes(namespace);
  });


  // Getting all collections
  allNamespaces.forEach((namespace) => {
    let tablesInNamespace = Object.keys(schema.namespaces[ namespace ].tables);
    tablesInNamespace.forEach((table) => {
      let tableWithNamespace = [ namespace, table ];
      allCollections.push(tableWithNamespace);
    });
  });

  validCollections = deepCopy(allCollections);

  switch (operation) {
    case "list of":
      if (nodeIndex !== 0) {
        let prev_table = question.nodes[ nodeIndex - 1 ].collection;
        let allButPreviousCollections = allCollections.filter((c) => !_.isEqual(c, prev_table));
        validCollections = deepCopy(allButPreviousCollections);
      }
      break;
    case "for each":
    case "number of":
    case "average":
    case "sum of":
      for (let tableWithNamespace of validCollections) {
        let [ namespace, table ] = tableWithNamespace;
        let fieldsForTable = schema.namespaces[ namespace ].tables[ table ].direct_fields;
        // Check if namespace key exists; if not, initialize it
        if (!validFieldsByCollection[ namespace ]) {
          validFieldsByCollection[ namespace ] = {};
        }
        if (fieldsForTable) {
          validFieldsByCollection[ namespace ][ table ] = deepCopy(fieldsForTable);
        }
      }
      break;
    default:
  }

  return {
    validFieldsByCollection: validFieldsByCollection,
    validCollections: validCollections,
  };
}


export function getValidOperations(nodeIndex, question) {
  let validOperations = [];

  if (nodeIndex === 0) {
    validOperations = requiresPreviousOperation;
    // console.log(validOperations);
  } else {
    let prevNodeOperation = question.nodes[ nodeIndex - 1 ].operation;
    switch (prevNodeOperation) {
      case "list of":
        validOperations = operationValues;
        break;
      case "number of":
      case "average":
      case "sum of":
        // We do not allow multiple metrics in the same query for now because it makes the SQL generation too complicated
        validOperations = [ "for each" ];
        break;
      case "max":
      case "min":
      case "group by":
      case "for each":
      case "top n by":
        validOperations = [ "for each" ];
        break;

      default:
      // do nothing
    }
  }
  return deepCopy(validOperations);
}

function createAdjacencyMatrix(schema) {
  const namespaces = Object.keys(schema.namespaces);
  const nodes = [];
  namespaces.forEach(namespace => {
    const tables = Object.keys(schema.namespaces[ namespace ].tables);
    tables.forEach(table => {
      nodes.push({ namespace, table });
    });
  });

  const nodeIndices = new Map();
  nodes.forEach(({ namespace, table }, index) => {
    if (!nodeIndices.has(namespace)) {
      nodeIndices.set(namespace, new Map());
    }
    nodeIndices.get(namespace).set(table, index);
  });

  const size = nodes.length;
  const adjacencyMatrix = Array.from({ length: size }, () =>
    Array.from({ length: size }, () => Infinity)
  );

  nodes.forEach(({ namespace, table }, i) => {
    const outRelationships = schema.namespaces[ namespace ].tables[ table ].outbound_relationships || {};
    const inRelationships = schema.namespaces[ namespace ].tables[ table ].inbound_relationships || {};

    // Iterate through outbound relationships
    Object.keys(outRelationships).forEach(targetNamespace => {
      Object.keys(outRelationships[ targetNamespace ]).forEach(targetTable => {
        if (nodeIndices.has(targetNamespace) && nodeIndices.get(targetNamespace).has(targetTable)) {
          const j = nodeIndices.get(targetNamespace).get(targetTable);
          adjacencyMatrix[ i ][ j ] = 1;
        }
      });
    });

    // Iterate through inbound relationships
    Object.keys(inRelationships).forEach(targetNamespace => {
      Object.keys(inRelationships[ targetNamespace ]).forEach(targetTable => {
        if (nodeIndices.has(targetNamespace) && nodeIndices.get(targetNamespace).has(targetTable)) {
          const j = nodeIndices.get(targetNamespace).get(targetTable);
          adjacencyMatrix[ i ][ j ] = -1;
        }
      });
    });

    adjacencyMatrix[ i ][ i ] = 0;
  });

  return { adjacencyMatrix, nodeIndices };
}



function createPathSegment(schema, sourceNamespace, sourceTable, targetNamespace, targetTable, direction) {
  const relationshipType = `${direction}_relationships`;
  const connectionInfo = schema.namespaces[ sourceNamespace ].tables[ sourceTable ][ relationshipType ][ targetNamespace ][ targetTable ];
  return {
    source: {
      namespace: sourceNamespace,
      table: sourceTable
    },
    target: {
      namespace: targetNamespace,
      table: targetTable
    },
    connectionInfo,
    type: relationshipType,
  };
}

export function floydWarshallAllShortestPaths(schema) {
  const { adjacencyMatrix, nodeIndices } = createAdjacencyMatrix(schema);
  const size = adjacencyMatrix.length;

  const dist = adjacencyMatrix.map((row) => row.slice());
  const next = Array.from({ length: size }, (_, i) =>
    Array.from({ length: size }, (_, j) => (i !== j && dist[ i ][ j ] !== Infinity ? [ j ] : []))
  );

  for (let k = 0; k < size; k++) {
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        const newPathLength = Math.abs(dist[ i ][ k ]) + Math.abs(dist[ k ][ j ]);
        if (newPathLength < Math.abs(dist[ i ][ j ])) {
          dist[ i ][ j ] = newPathLength;
          next[ i ][ j ] = next[ i ][ k ].slice();
        } else if (newPathLength === dist[ i ][ j ] && newPathLength !== Infinity) {
          next[ i ][ j ] = Array.from(
            new Set([ ...next[ i ][ j ], ...next[ i ][ k ] ])
          );
        }
      }
    }
  }

  function reconstructPaths(i, j) {
    if (next[ i ][ j ].length === 0) {
      return [];
    }
    const paths = [];
    const path = [ i ];
    function backtrack(i, j) {
      if (i === j) {
        paths.push(path.slice());
      } else {
        next[ i ][ j ].forEach((k) => {
          path.push(k);
          backtrack(k, j);
          path.pop();
        });
      }
    }
    backtrack(i, j);
    return paths;
  }

  const nodes = [];
  for (const [ namespace, tables ] of nodeIndices) {
    for (const table of tables.keys()) {
      nodes.push({ namespace, table });
    }
  }

  const allShortestPaths = {};
  for (let i = 0; i < nodes.length; i++) {
    const start = nodes[ i ];
    allShortestPaths[ start.namespace ] = allShortestPaths[ start.namespace ] || {};
    allShortestPaths[ start.namespace ][ start.table ] = {};

    for (let j = 0; j < nodes.length; j++) {
      const end = nodes[ j ];
      const pathIndicesList = reconstructPaths(i, j);
      allShortestPaths[ start.namespace ][ start.table ][ end.namespace ] = allShortestPaths[ start.namespace ][ start.table ][ end.namespace ] || {};
      allShortestPaths[ start.namespace ][ start.table ][ end.namespace ][ end.table ] = pathIndicesList.map((pathIndices) => {
        return pathIndices
          .slice(0, -1)
          .map((sourceIndex, idx) => {
            const targetIndex = pathIndices[ idx + 1 ];
            const sourceNode = nodes[ sourceIndex ];
            const targetNode = nodes[ targetIndex ];
            const direction =
              dist[ sourceIndex ][ targetIndex ] === 1 ? "outbound" : "inbound";
            return createPathSegment(schema, sourceNode.namespace, sourceNode.table, targetNode.namespace, targetNode.table, direction);
          });
      });
    }
  }

  return allShortestPaths;
}

