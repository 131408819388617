import React, { useState } from "react";
import { Space, Layout, Button, Tabs, Collapse } from "antd";
import ReactPlayer from "react-player/vimeo";
import DatasourceList from "./DatasourceList.jsx";

import { Divider, Col, Card, Row, Image } from "antd";
import { Typography } from "antd";
import {
  CheckCircleOutlined,
  BarChartOutlined,
  FilterOutlined,
  SafetyOutlined,
  AudioMutedOutlined,
  ControlOutlined,
} from "@ant-design/icons";
const { Title } = Typography;
const { Header, Content, Footer } = Layout;
const { Meta } = Card;
import { Link } from "react-router-dom";
const { Panel } = Collapse;
import { useEffect, useContext } from "react";
import UserContext from "../../Contexts.js";
import { rootURI } from "../../utils/common_definitions.js";
import { Spinner } from "../spinner/Spinner.jsx";

const Dashboard = (props) => {
  const { userObject, setUserObject } = useContext(UserContext);
  const [ userInfo, setUserInfo ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(rootURI + "/users/" + userObject.user_id + "/all_data", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userObject.user_token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          for (let ds of result.datasources) {
            ds.questions = ds.questions.reduce((obj, item) => ((obj[ item.id ] = item), obj), {});
          }
          result.datasources = result.datasources.reduce((obj, item) => ((obj[ item.id ] = item), obj), {});

          setUserInfo(result);
          setLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // setLoading(false);
          console.log(error);
        }
      );
  }, []); // <-- empty array means 'run once'

  if (loading) {
    return <Spinner />;
  } else {
    console.log("rendering: ");
    console.log(userInfo);
    return <DatasourceList userInfo={userInfo} setLoading={setLoading} setUserInfo={setUserInfo}></DatasourceList>;
  }
};

export default Dashboard;
