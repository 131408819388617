import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 44,
		}}
		spin
	/>
);

export function Spinner(props) {
	return <Spin indicator={antIcon} />;
}
