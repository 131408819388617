import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQllhSHxSdkdmXH9ccnY=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpRd0diWX1bdXRRRWI=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdERhWH9cdXFVT2Fe;MTgwNDkxOEAzMjMxMmUzMTJlMzMzNVpyQzQyM0hpV2psUVd4ZHVnNmhMKzRjbHFiM01jZEtWNm5kdS9xWTJQL2c9;MTgwNDkxOUAzMjMxMmUzMTJlMzMzNUx6cVlZRGFJRUdBdExGVDNYU2hIMjNCalBkSmN0clRUbjltWHVDdEIrWlk9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckdiW35ecnFQRWlaUw==;MTgwNDkyMUAzMjMxMmUzMTJlMzMzNW95WWNMaUVQK09Ta2p2WHBEV2toQyttS2cySlNwOHo2ODQwQnRnNVppaFk9;MTgwNDkyMkAzMjMxMmUzMTJlMzMzNWFWb0hsSkRMUEpOZTYzOE1JTVphemc5NFhuWWtjL3k4OTlFVGYrQ1haRDQ9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdERhWH9cdXFQRWhU;MTgwNDkyNEAzMjMxMmUzMTJlMzMzNWpCZTVZWUhkaUlIWjdSdnE5WWdoQkFzN2NoUTRWL0hjRHVtY1ZhazhrdFE9;MTgwNDkyNUAzMjMxMmUzMTJlMzMzNWE1YTRPNmpEckF4RGh0Q2d2YUVuV0hKZ0dVVGF2Y244WHlXbnpjWHpOeUU9;MTgwNDkyNkAzMjMxMmUzMTJlMzMzNW95WWNMaUVQK09Ta2p2WHBEV2toQyttS2cySlNwOHo2ODQwQnRnNVppaFk9');

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
